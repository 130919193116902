/**
 * @name: 采购管理-采购明细查询接口文件
 * @author: lili
 * @date: 2023-08-04 17:46
 * @description： 采购管理-采购明细查询接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {IOrderDetailsParams} from "@/apis/purchase/list/types";

/**
 * 查询采购明细列表
 * @param params 查询参数
 * @returns
 */
export const orderDetailsQueryApi = (params: IOrderDetailsParams) => get("/admin/purchase/order/orderDetailsPage", params)

/**
 * 查询采购明细导出
 * @param params 查询参数
 * @returns
 */
export const orderDetailsExportApi = (params: IOrderDetailsParams) => get("/admin/purchase/order/export", params,"blob")
