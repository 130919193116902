
/**
 * @name: 采购管理-采购明细查询
 * @author:lili
 * @date: 2023-08-04 17:53
 * @description： 采购管理-采购明细查询
 * @update: 2023-08-04 17:53
 */
import {Component, Vue} from "vue-property-decorator"
import type {ICrudOption} from "@/types/m-ui-crud"
import {IOrderDetails, IOrderDetailsParams} from "@/apis/purchase/list/types";
import {orderDetailsExportApi, orderDetailsQueryApi} from "@/apis/purchase/list";
import {deepCopy, exportFile} from "@/utils/common";

@Component({})
export default class purchaseList extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: [] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IOrderDetailsParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<IOrderDetails> = {}

  //顶部合计数据
  pageData: any = {
    settlementPrice: 0, //结算金额
    orderPrice: 0,//订单金额
    payPrice: 0,//支付金额
    productPrice: 0,//商品金额
    purchaseQty: 0,//采购单数量
    checkQty: 0,//核对数量
    decreaseQty: 0//损益数量
  }
  // crud配置信息
  crudOption: ICrudOption = {
    searchBox: true,
    labelWidth: '180px',
    menu: false,
    column: [
      {
        label: "采购订单编号",
        prop: "purchaseOrderSn",
        align: "center",
        search: true,
        width: 180,
      },
      {
        label: "批次号",
        prop: "batchNo",
        align: "center",
      },
      {
        label: "商品名称",
        prop: "productName",
        search: true,
        align: "center",
      },
      {
        label: "商品金额",
        prop: "productPrice",
        align: "center",
        search: true,
        hide:true
      },
      {
        label: "采购员",
        prop: "personnelName",
        search: true,
        align: "center",
      },
      {
        label: "采购价",
        prop: "purchasePrice",
        align: "center",
        slot: true
      },
      {
        label: "采购数量",
        prop: "purchaseQty",
        align: "center",
        slot: true
      },
      {
        label: "核对数量",
        prop: "checkQty",
        align: "center",
        slot: true
      },
      {
        label: "损溢数量",
        prop: "decreaseQty",
        align: "center",
        help: '核对数量 - 采购数量',
        slot: true
      },
      {
        label: "商品金额",
        prop: "productTotalPrice",
        align: "center",
        help: "采购价 * 采购数量"
      },
      {
        label: "供应商",
        prop: "supplierName",
        align: "center",
        search: true,
      },

      {
        label: "是否异常",
        prop: "isAbnormal",
        align: 'center',
        search: true,
        type: "select",
        dicData: [{label: '是', value: true}, {label: '否', value: false}],
        span: 24,
      },
      {
        label: "采购时间",
        prop: "purchaseTime",
        align: "center",
        search: true,
        searchSlot:true,
      },
    ]
  }

  /**
   * 导出excel
   */
  exportExcel() {
    const form: IOrderDetailsParams = deepCopy(this.queryParam);
    if (this.queryParam.purchaseTime && this.queryParam.purchaseTime.length) {
      form.purchaseStartTime = this.queryParam.purchaseTime[0]
      form.purchaseEndTime = this.queryParam.purchaseTime[1]
    } else {
      form.purchaseStartTime = ""
      form.purchaseEndTime = ""
    }
    delete form.purchaseTime
    orderDetailsExportApi(form).then(e => {
      exportFile(e, '采购明细.xlsx')
    })
  }

  getList() {
    const form: IOrderDetailsParams = deepCopy(this.queryParam);
    if (this.queryParam.purchaseTime && this.queryParam.purchaseTime.length) {
      form.purchaseStartTime = this.queryParam.purchaseTime[0]
      form.purchaseEndTime = this.queryParam.purchaseTime[1]
    } else {
      form.purchaseStartTime = ""
      form.purchaseEndTime = ""
    }
    delete form.purchaseTime
    orderDetailsQueryApi(form).then(e => {
      this.pageData.checkQty = e.checkQty || 0;
      this.pageData.decreaseQty = e.decreaseQty || 0;
      this.pageData.purchaseQty = e.purchaseQty || 0;
      this.pageData.productPrice = e.productPrice || 0;
      this.pageData.orderPrice = e.orderPrice || 0;
      this.pageData.payPrice = e.payPrice || 0;
      this.pageData.settlementPrice = e.settlementPrice || 0;
      let {commonPage} = e;
      //@ts-ignore
      this.tableData = commonPage.list;
      this.tableTotal = commonPage.total
    })
  }

  created () {
    this.getList()
  }
}
